/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { MdSearch } from 'react-icons/md'
import { FaRegBuilding } from 'react-icons/fa'
import { Avatar } from '../../components/Avatar'
import { SearchInput } from '../../components/SearchInput'
import { CompanyButton, SearchForUserContainer, UserButton } from './styles'
import { Company } from '../../entities/company'
import { useUser } from '../../hooks/use-user'
import { useCompany } from '../../hooks/use-company'

export function SearchForUser() {
    // hooks
    const { getAllUsers, users, activeUser } = useUser()
    const { getAllCompanies, companies } = useCompany()
    const [selectedCompany, setSelectedCompany] = useState<Company>()

    // state
    const [query, setQuery] = useState<string | undefined>('')

    useEffect(() => {
        let newQuery

        if (selectedCompany) {
            newQuery = `${selectedCompany.name} ${query}`
        } else {
            newQuery = query
        }
        ;(async () => {
            if (newQuery) {
                await getAllUsers(newQuery)
                if (!selectedCompany) {
                    await getAllCompanies(newQuery)
                }
            }
        })()
    }, [query, selectedCompany])

    return (
        <SearchForUserContainer>
            {selectedCompany && (
                <CompanyButton
                    key={selectedCompany.name}
                    type="button"
                    onClick={() => {
                        setSelectedCompany(undefined)
                        setQuery('')
                    }}
                >
                    <div className="avatar-icon">
                        <FaRegBuilding size={26} />
                    </div>
                    <div className="user-info">
                        <h1 className="name">{selectedCompany.name}</h1>
                        <h1 className="company f14-500-gray ">
                            {
                                selectedCompany.documents.find(
                                    (doc) => doc.type === 'CNPJ'
                                )?.value
                            }
                        </h1>
                    </div>
                </CompanyButton>
            )}
            <div className="search-all-container">
                <SearchInput
                    name="search"
                    label="Pesquisar"
                    icon={MdSearch}
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                />
                <div className="users-container">
                    {!selectedCompany && companies && companies.length > 0 && (
                        <div>
                            {companies.map((company) => {
                                return (
                                    <UserButton
                                        key={company.name}
                                        type="button"
                                        onClick={() => {
                                            setSelectedCompany(company)
                                            setQuery('')
                                            getAllUsers(company.name)
                                        }}
                                    >
                                        <div className="avatar-icon">
                                            <FaRegBuilding size={26} />
                                        </div>
                                        <div className="user-info">
                                            <h1 className="name">
                                                {company.name}
                                            </h1>
                                            <h1 className="company f14-500-gray ">
                                                {
                                                    company.documents.find(
                                                        (doc) =>
                                                            doc.type === 'CNPJ'
                                                    )?.value
                                                }
                                            </h1>
                                        </div>
                                    </UserButton>
                                )
                            })}
                        </div>
                    )}
                    {users && (
                        <div>
                            {users.map((user) => {
                                return (
                                    <UserButton
                                        type="button"
                                        onClick={() => activeUser(user.id)}
                                    >
                                        <div className="avatar">
                                            <Avatar
                                                label={`${user.firstName} ${user.lastName}`}
                                                img={user.photo?.url}
                                            />
                                        </div>
                                        <div className="user-info">
                                            <h1 className="name">
                                                {user.firstName} {user.lastName}
                                            </h1>
                                            <h1 className="company f14-500-gray ">
                                                {
                                                    user.companyInfo[0]
                                                        .userCorporateEmail
                                                }
                                            </h1>
                                        </div>
                                    </UserButton>
                                )
                            })}
                        </div>
                    )}
                </div>
            </div>
        </SearchForUserContainer>
    )
}
