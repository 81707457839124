/* eslint-disable no-param-reassign */
import React, { createContext, ReactNode, useContext, useState } from 'react'
import { toast } from 'react-toastify'
import qs from 'qs'
import { CreatePaymentMethodDto } from '../dtos/create-payment-method'
import { EditPaymentMethod } from '../dtos/edit-payment-method'
import { ElementToAddSettings } from '../dtos/element-to-add-settings'
import { PaymentMethod } from '../entities/payment-method'
import { api } from '../service/api'
import { useAuth } from './use-auth'
import { useCompany } from './use-company'
import { PaymentFilter } from '../entities/payment-filter'
import { PaymentMethodType } from '../enums/payment-method-type.enum'

interface PaymentContext {
    getPaymentMethods(): Promise<PaymentMethod[] | undefined>
    createPaymentMethod(data: CreatePaymentMethodDto): Promise<any>
    choosePaymentMethodToEdit(data: PaymentMethod | undefined): void
    elementToAddSettingsHandler(data: ElementToAddSettings): void
    handlePaymentFilter(filter: PaymentFilter): void
    paymentFilter: PaymentFilter
    editPaymentMethod(
        data: EditPaymentMethod,
        id: string
    ): Promise<PaymentMethod | undefined>
    deletePaymentMethod(id: string): Promise<boolean | undefined>
    elementToAddSettings: ElementToAddSettings
    paymentMehodToEdit?: PaymentMethod
    paymentMethods: PaymentMethod[]
    busy: boolean
}

interface Props {
    children: ReactNode
}

const PaymentContext = createContext({} as PaymentContext)

export function PaymentProvider({ children }: Props) {
    // hooks
    const { token } = useAuth()
    const { company } = useCompany()
    // state
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([])
    const [busy, setBusy] = useState(false)
    const [paymentMehodToEdit, setPaymentMethodToEdit] =
        useState<PaymentMethod>()
    const [elementToAddSettings, setElementToAddSettings] =
        useState<ElementToAddSettings>({
            listToAdd: [],
            list: [],
            type: 'CLOSED',
            id: '',
            paymentMethod: undefined,
        })
    const [paymentFilter, setPaymentFilter] = useState<PaymentFilter>({
        managementInfos: [],
        isActive: true,
    })

    const handlePaymentFilter = (filter: PaymentFilter) => {
        setPaymentFilter({
            managementInfos: [
                ...filter.managementInfos.map((m) => {
                    return {
                        id: m.id,
                        options: [...m.options],
                    }
                }),
            ],
            accessibleByUserId: filter.accessibleByUserId,
            productTypes: filter.productTypes ? [...filter.productTypes] : [],
            isActive: filter.isActive,
            canBeUsedOnExpenses: filter.canBeUsedOnExpenses,
            isGlobal: filter.isGlobal,
        })
    }

    async function getPaymentMethods() {
        try {
            setBusy(true)
            const response = await api.get<PaymentMethod[]>(
                '/admins/payment-methods',
                {
                    headers: { authorization: `Bearer ${token}` },
                    params: {
                        company_id: company?.id || '',
                        is_active: paymentFilter.isActive,
                        can_be_used_on_expenses:
                            paymentFilter.canBeUsedOnExpenses,
                        is_global: paymentFilter.isGlobal,
                        product_types: paymentFilter.productTypes,
                        management_info:
                            paymentFilter.managementInfos &&
                            paymentFilter.managementInfos.length > 0
                                ? paymentFilter.managementInfos
                                : undefined,
                        accessible_by_user_id:
                            paymentFilter.accessibleByUserId || undefined,
                    },
                    paramsSerializer: (params) =>
                        qs.stringify(params, { arrayFormat: 'indices' }),
                }
            )
            setPaymentMethods(
                response.data.sort((payment) => {
                    if (payment.type === PaymentMethodType.INVOICE) return -1
                    return 1
                })
            )
            setBusy(false)
            return response.data
        } catch (error) {
            setBusy(false)
            return undefined
        }
    }

    async function createPaymentMethod(data: CreatePaymentMethodDto) {
        try {
            const response = await api.post<PaymentMethod>(
                '/payment-methods',
                data,
                {
                    headers: { authorization: `Bearer ${token}` },
                }
            )
            if (response.status === 201) {
                toast.success('Forma de pagamento adicionada com sucesso')
                setPaymentMethods([...paymentMethods, response.data])
            }
            return response.data
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    async function editPaymentMethod(data: EditPaymentMethod, id: string) {
        try {
            const response = await api.patch<PaymentMethod>(
                `/payment-methods/${id}`,
                data,
                {
                    headers: { authorization: `Bearer ${token}` },
                    params: { company_id: company?.id || '' },
                }
            )
            setPaymentMethods((prev) => {
                const index = prev.findIndex((p) => p.id === id)
                if (index !== undefined) {
                    prev[index] = response.data
                }
                return [...prev]
            })
            return response.data
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    async function deletePaymentMethod(id: string) {
        try {
            const response = await api.delete<PaymentMethod>(
                `/payment-methods/${id}`,
                {
                    headers: { authorization: `Bearer ${token}` },
                    params: { company_id: company?.id || '' },
                }
            )
            if (response.status === 204) {
                setPaymentMethods((prev) => {
                    return [...prev.filter((p) => p.id !== id)]
                })
            }
            return true
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    function choosePaymentMethodToEdit(data: PaymentMethod | undefined) {
        setPaymentMethodToEdit(data)
    }

    function elementToAddSettingsHandler(data: ElementToAddSettings) {
        setElementToAddSettings({
            list: [...data.list],
            listToAdd: [...data.listToAdd],
            type: data.type,
            id: data.id,
            paymentMethod: data.paymentMethod,
            option: data.option,
            managementId: data.managementId,
        })
    }

    return (
        <PaymentContext.Provider
            value={{
                getPaymentMethods,
                paymentMethods,
                choosePaymentMethodToEdit,
                paymentMehodToEdit,
                elementToAddSettingsHandler,
                elementToAddSettings,
                editPaymentMethod,
                createPaymentMethod,
                handlePaymentFilter,
                paymentFilter,
                busy,
                deletePaymentMethod,
            }}
        >
            {children}
        </PaymentContext.Provider>
    )
}

export function usePayment(): PaymentContext {
    const context = useContext(PaymentContext)
    if (!context) {
        throw new Error('usePayment must be within a Provider')
    }
    return context
}
